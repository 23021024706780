import React from 'react'
import * as S from './styled'

const NavLogo = ({ navLogo, navLink }) => {
  return (
    <S.NavContainerLogo
      id="NavContainerLogo-div"
      className="NavContainerLogo-div"
      data-testid="nav-containerlogo-div"
    >
      <S.NavLink
        href={navLink}
        id="NavLinkLogo-a"
        className="NavLinkLogo-a"
        data-testid="nav-linklogo-a"
      >
        <S.ImgLogo
          id="NavImgLogo-img"
          className="NavImgLogo-img"
          data-testid="nav-imglogo-img"
          src={navLogo}
          alt="NAV Logo"
          loading="eager"
        />
      </S.NavLink>
    </S.NavContainerLogo>
  )
}

export default NavLogo
