import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslations } from 'gatsby-plugin-translate'
import { useHeaderContext } from '../../../Hooks/headerStates'

import SchedulingButton from '../SchedulingButton'

import * as S from './styled'

const ButtonsMobile = () => {
  const data = useStaticQuery(graphql`
    query {
      links: wordpressAltaThemeSettings {
        examination_results_link
        scheduling_link_rj
        scheduling_link_sp
        scheduling_link_df
      }
    }
  `)

  const examinatioResultslLink = data.links.examination_results_link
  const schedulelLinkSp = data.links.scheduling_link_sp
  const schedulelLinkRj = data.links.scheduling_link_rj
  const schedulelLinkDf = data.links.scheduling_link_df

  const isExternalLink = url => {
    return url.match(/^https?:\/\//g)
  }

  const menuMobileCheck = useHeaderContext()
  const menuOpenState = menuMobileCheck.getMenuMobileOpenState()

  const t = useTranslations()
  const translatedExamResult = t`Resultados de exames`

  return (
    <S.ButtonsContainer open={menuOpenState} id="buttonMobile">
      <SchedulingButton
        schedulelLinkSp={schedulelLinkSp}
        schedulelLinkRj={schedulelLinkRj}
        schedulelLinkDf={schedulelLinkDf}
      />
      <S.DoctorButton
        href={examinatioResultslLink}
        target={isExternalLink(examinatioResultslLink) ? '_blank' : ''}
      >
        {translatedExamResult}
      </S.DoctorButton>
    </S.ButtonsContainer>
  )
}

export default ButtonsMobile
