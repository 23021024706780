import React from 'react'
import PropTypes from 'prop-types'

import SearchForm from './SearchForm'
import SearchResults from './SearchResults'

import * as S from './styled'

const SearchMobile = ({
  openMobileState,
  handleSubmit,
  query,
  inputChange,
  clearSearch,
  notSearchResult,
  searchResult,
}) => {
  return (
    <S.MainSearch
      id="mainSeacrhMobile"
      open={openMobileState}
      className={openMobileState ? 'open-animation' : 'close-animation'}
    >
      <S.SeacrhFormResultsContainer>
        <SearchForm
          handleSubmit={handleSubmit}
          query={query}
          inputChange={inputChange}
          clearSearch={clearSearch}
          className={openMobileState ? 'open-animation' : 'close-animation'}
        />
        {query && <SearchResults notSearchResult={notSearchResult} searchResult={searchResult} />}
      </S.SeacrhFormResultsContainer>
    </S.MainSearch>
  )
}
SearchMobile.propTypes = {
  openMobileState: PropTypes.bool,
  handleSubmit: PropTypes.func,
  query: PropTypes.string,
  inputChange: PropTypes.func,
  clearSearch: PropTypes.func,
  notSearchResult: PropTypes.bool,
  searchResult: PropTypes.array,
}

export default SearchMobile
