import styled, { keyframes } from 'styled-components'
import { KeyboardArrowLeft } from '@styled-icons/material-rounded'

import { slideInRight } from 'react-animations'

const slideInRightAnimation = keyframes`${slideInRight}`

export const SubmenuMobile = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 2.4rem;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: ${props => props.theme.colors.primary};
  list-style: none;
  box-sizing: border-box;
  animation: 200ms ${slideInRightAnimation};

  @media only screen and (max-width: 320px) {
    height: 100vh;
  }
`

export const SubmenuTitleContainer = styled.div`
  display: flex;
  align-items: center;
`

export const SubmenuTitle = styled.p`
  margin: 0;
  font-size: 2rem;
  color: ${props => props.theme.colors.secondary};
`

export const SubmenuItem = styled.li`
  color: ${props => props.theme.colors.secondary};
`

export const SubmenuLink = styled.a`
  display: block;
  text-decoration: none;
  padding: 0.5rem 3.2rem;
  color: ${props => props.theme.colors.secondary};
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
`

// ICONS

export const ArrowLeftIcon = styled(KeyboardArrowLeft)`
  color: ${props => props.theme.colors.secondary};
`
