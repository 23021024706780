import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const Breadcrumb = ({ location, pageContext, hasHeader }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  const generateCrumbLabel = ({ pathname }) => {
    const splitedPathname = pathname.split('/')
    const lastPartPathName = splitedPathname[splitedPathname.length - 1]
    return lastPartPathName.toLowerCase().replace(/-/g, ' ')
  }

  return (
    <S.BreadcrumbHolder hasHeader={hasHeader}>
      <S.Breadcrumb crumbs={crumbs} crumbLabel={generateCrumbLabel(location)} />
    </S.BreadcrumbHolder>
  )
}

Breadcrumb.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
  hasHeader: PropTypes.bool,
}

export default Breadcrumb
