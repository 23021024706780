import styled from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'gatsby'
import { Container } from '../../shared/container'

export const InstitutionalBackground = styled.div`
  padding: 3rem 0;
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.primary};

  ${media.between('medium', 'huge')`
    padding: 2rem 0;
  `}

  ${media.lessThan('medium')`
    padding: 3.9rem 0;
    background-color: ${props => props.theme.colors.lightGray};
    color: ${props => props.theme.colors.darkerGray};
  `}
`

export const InstitutionalContainerCustom = styled(Container)`
  ${media.between('medium', 'large')`
    display: flex;
    align-items: center;
    margin: 0 4.8rem;
  `}

  ${media.lessThan('medium')`
    display: flex;
    flex-direction: column;
  `}
`

export const InstitucionalExternalLinks = styled.a`
  width: max-content;
  font-family: 'Dasa Sans';
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-decoration: none;
  color: ${props => props.theme.colors.primary};
  margin-right: 2.4rem;

  &:hover {
    text-decoration: underline;
  }

  ${media.between('medium', 'huge')`
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin: 0 2.4rem 2.4rem 0;
  `}

  ${media.lessThan('medium')`
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin: 0 0 2.4rem;
    color: ${props => props.theme.colors.darkerGray};
  `}
`

export const InstitucionalInternalLinks = styled(Link)`
  width: max-content;
  font-family: 'Dasa Sans';
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-decoration: none;
  color: ${props => props.theme.colors.primary};
  margin-right: 2.4rem;

  &:hover {
    text-decoration: underline;
  }

  ${media.between('medium', 'huge')`
    font-size: 1.4rem;
    line-height: 1.4rem;
    margin: 0 2.4rem 2.4rem 0;
    color: '#353434';
  `}

  ${media.lessThan('medium')`
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin: 0 0 2.4rem;
    color: ${props => props.theme.colors.darkerGray};
  `}
`

export const DasaFooterLogo = styled.img`
  float: right;
  width: 5.6rem;
  height: 1.6rem;

  ${media.lessThan('medium')`
    margin-top: 2.6rem;
  `}
`
