import React from 'react'
import PropTypes from 'prop-types'
import { formatTextToNumbers } from '@cms/utils'

import { useTranslations } from 'gatsby-plugin-translate'

import * as S from './styled'

const ContactUs = ({
  socialsInfo: { phone_sp: phoneSP, phone_rj: phoneRJ, phone_df: phoneDF },
}) => {
  const t = useTranslations()
  const translatedContactUs = t`Fale conosco`

  const contactData = [
    {
      label: 'São Paulo',
      phone: phoneSP,
    },
    {
      label: 'Rio de Janeiro',
      phone: phoneRJ,
    },
    {
      label: 'Distrito Federal',
      phone: phoneDF,
    },
  ]

  return (
    <>
      <S.ContactUsTitle>{translatedContactUs}</S.ContactUsTitle>
      {contactData.map(region => (
        <S.PhoneWrapper key={region.label}>
          <S.PhoneLabel>{region.label}</S.PhoneLabel>
          <S.PhoneNumber href={`tel:+55${formatTextToNumbers(region.phone)}`}>
            {region.phone}
          </S.PhoneNumber>
        </S.PhoneWrapper>
      ))}
    </>
  )
}

ContactUs.propTypes = {
  socialsInfo: PropTypes.objectOf(
    PropTypes.shape({
      phoneSP: PropTypes.string,
      phoneRJ: PropTypes.string,
    })
  ),
}

export default ContactUs
