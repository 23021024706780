import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  margin: 0 9.6rem;

  ${media.lessThan('medium')`
    margin: 0 2.4rem;
  `};
`
