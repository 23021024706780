import styled, { keyframes } from 'styled-components'
import Img from 'gatsby-image'
import { fadeIn } from 'react-animations'

const fadeAnimation = keyframes`${fadeIn}`

export const LogoContainer = styled.div`
  grid-area: logo;
  align-self: center;
  background-color: transparent;

  img {
    width: 100%;
    height: auto;
  }
`

export const LogoImg = styled(Img)`
  animation: 1s ${fadeAnimation};
`

export const LogoExraImg = styled.img`
  animation: 1s ${fadeAnimation};
`
