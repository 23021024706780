const axios = require('axios')

const SLACK_URL = process.env.SLACK_URL

const sendSlack = async message => {
  await axios({ method: 'POST', url: SLACK_URL, data: { text: message } })
}

const sendFormattedSlack = async attachment => {
  await axios({ method: 'POST', url: SLACK_URL, data: { attachments: [attachment] } })
}

const slackColors = {
  success: '#36a64f',
  error: '#F60000',
  warning: '#F6F600',
}

module.exports = { sendSlack, sendFormattedSlack, slackColors }
