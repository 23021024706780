import styled from 'styled-components'
// import media from 'styled-media-query'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'

export const CloseIcon = styled(CloseOutline)`
  width: 3rem;
  color: ${props => props.theme.colors.secondary};
  margin: 0;
  cursor: pointer;
`
