import React from 'react'
import PropTypes from 'prop-types'
import { useHeaderContext } from '../../../../Hooks/headerStates'

import SubmenuMobile from '../Submenu'

import * as S from './styled'

const MenuItemMobile = ({ menuItem: { title, url, wordpress_children: submenu } }) => {
  const headerContext = useHeaderContext()
  const submenuVisible = headerContext.getSubmenuMobileVisibleState()

  const hasSubmenu = submenu && submenu.length > 0

  const scrollToTopMenu = () => {
    const menu = document.getElementById('menuMobile')
    const isFirefox = typeof InstallTrigger !== 'undefined'
    menu.scrollTo({ top: 0, left: 0, behavior: isFirefox ? 'auto' : 'smooth' })
  }

  const openCloseSubmenu = (e, submenuTitle) => {
    if (e.target.tagName === 'UL' || e.target.tagName === 'A') return
    headerContext.setSubmenuMobileVisibleState({ [submenuTitle]: !submenuVisible[submenuTitle] })
    scrollToTopMenu()
  }

  return (
    <>
      {hasSubmenu ? (
        <S.MenuItem
          onClick={e => {
            openCloseSubmenu(e, title)
          }}
        >
          <S.SubmenuTitle>
            {title}
            <S.ArrowRightIcon size={30} />
          </S.SubmenuTitle>
          {submenuVisible[title] ? (
            <SubmenuMobile onClick={scrollToTopMenu} title={title} submenu={submenu} />
          ) : null}
        </S.MenuItem>
      ) : (
        <S.MenuItem>
          <S.MenuLink
            href={url === '' ? '#' : url}
            target={url.match(/^https?:\/\//g) ? '_blank' : ''}
          >
            {title}
          </S.MenuLink>
        </S.MenuItem>
      )}
    </>
  )
}

MenuItemMobile.propTypes = {
  menuItem: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    wordpress_children: PropTypes.array,
  }),
}

export default MenuItemMobile
