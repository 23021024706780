import React from 'react'
import PropTypes from 'prop-types'
import { useTranslations } from 'gatsby-plugin-translate'
import SyncLoader from 'react-spinners/SyncLoader'
import * as S from './styled'

const SearchResults = ({ notSearchResult, searchResult, loading }) => {
  const t = useTranslations()
  const translatedNotSearchResultsMessage = t`Exame ou vacina não encontrado, tente novamente`
  const translatedSearchResultsMessage = t`Sugestões de busca`
  const translatedSearchMessage = t`Pesquisando`

  return (
    <S.ResultsContainer id="search-result">
      <S.ResultsLoadingContainer>
        {notSearchResult ? (
          <S.ResultLabel>{translatedNotSearchResultsMessage}.</S.ResultLabel>
        ) : (
          <>
            {searchResult.length && !loading ? (
              <S.ResultLabel>{translatedSearchResultsMessage}</S.ResultLabel>
            ) : (
              <S.ResultLabel>{translatedSearchMessage}</S.ResultLabel>
            )}
            <S.LoadingContainer>
              <SyncLoader loading={loading} color="#beeaef" margin="3px" size="6px" />
            </S.LoadingContainer>
          </>
        )}
      </S.ResultsLoadingContainer>

      {searchResult.map((product, index) => (
        <S.ResultsItem key={index} href={product.slug}>
          {product.nome}
        </S.ResultsItem>
      ))}
    </S.ResultsContainer>
  )
}

SearchResults.propTypes = {
  notSearchResult: PropTypes.bool.isRequired,
  searchResult: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default SearchResults
