import styled from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'gatsby'
import { TranslateLink } from 'gatsby-plugin-translate'
import { KeyboardArrowDown } from '@styled-icons/material-rounded'

export const SubmenuDesktop = styled.ul`
  display: ${props => (props.visible ? 'block' : 'none')};
  position: absolute;
  left: -60px;
  top: 41px;
  width: 20.8rem;
  z-index: 3;
  padding: 1.5rem 0;
  list-style: none;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    top: -1rem;
    right: 7.3rem;
    border-right: 1rem solid transparent;
    border-left: 1rem solid transparent;
    border-bottom: 1.1rem solid ${props => props.theme.colors.white};
  }
`

export const SubmenuItem = styled.li`
  color: ${props => props.theme.colors.white};

  /* ${media.greaterThan('medium')`
    &:hover {
      background-color: ${props => props.theme.colors.secondary};
    }
  `} */
`

export const SubmenuLink = styled.a`
  display: block;
  text-decoration: none;
  padding: 0.5rem 3.2rem;
  color: ${props => props.theme.colors.black};
  font-size: 1.4rem;
  -webkit-font-smoothing: antialiased;
`

// ICONS

export const ArrowDownIcon = styled(KeyboardArrowDown)`
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  opacity: ${props => (props.visible ? 1 : 0.8)};
`
