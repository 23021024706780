import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import altaTheme from './altaTheme'

const Theme = ({ children }) => {
  return <ThemeProvider theme={altaTheme}>{children}</ThemeProvider>
}

Theme.propTypes = {
  children: PropTypes.element,
}

export default Theme
