import React from 'react'
import { HeaderProvider } from './Hooks/headerStates'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'

import * as S from './styled'

const Header = () => {
  return (
    <HeaderProvider>
      <S.Header>
        <HeaderMobile />
        <HeaderDesktop />
      </S.Header>
    </HeaderProvider>
  )
}

export default Header
