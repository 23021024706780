const { flatMap } = require('./arrays')
const {
  formatPhoneMask,
  formatIdMask,
 } = require('./masks')
const replaceSpecialChars = require('./removeCaracteres')
const removeHTML = require('./removeHTML')
const {
  slugfy,
  capitalize,
  htmlentities,
  serializeUrls,
  removeLastChar,
  formatTextToNumbers,
  normalizeText,
} = require('./strings')
const { isHome, isMobile, isUrl, isExternalLink } = require('./validators')
const getBackOfficeToken = require('./backofficeToken')
const mediaQuery = require('./mediaQuery')
const { sendSlack, sendFormattedSlack, slackColors } = require('./slack')
const { get, set } = require('./redis')
const debounce = require('./debounce')

module.exports = {
  flatMap,
  formatPhoneMask,
  formatIdMask,
  replaceSpecialChars,
  removeHTML,
  slugfy,
  capitalize,
  htmlentities,
  serializeUrls,
  removeLastChar,
  formatTextToNumbers,
  normalizeText,
  isHome,
  isMobile,
  isUrl,
  isExternalLink,
  getBackOfficeToken,
  mediaQuery,
  sendSlack,
  sendFormattedSlack,
  slackColors,
  get,
  set,
  debounce,
}
