import React from 'react'
import PropTypes from 'prop-types'

import SearchButton from './SearchButton'
import SearchForm from './SearchForm'
import SearchResults from './SearchResults'

import * as S from './styled'

const SearchDesktop = ({
  openDesktopState,
  openDesktop,
  handleSubmit,
  query,
  inputChange,
  clearSearch,
  notSearchResult,
  searchResult,
  loading,
}) => (
  <>
    <S.Overlay id="searchOverlay" showOverlay={openDesktopState} onClick={openDesktop} />
    <S.MainSearch>
      <SearchForm
        handleSubmit={handleSubmit}
        searchOpen={openDesktopState}
        query={query}
        inputChange={inputChange}
        clearSearch={clearSearch}
      />
      <SearchButton onClick={openDesktop} searchOpen={openDesktopState} />
      {query && (
        <SearchResults
          notSearchResult={notSearchResult}
          searchResult={searchResult}
          loading={loading}
        />
      )}
    </S.MainSearch>
  </>
)

SearchDesktop.propTypes = {
  openDesktopState: PropTypes.bool,
  openDesktop: PropTypes.func,
  handleSubmit: PropTypes.func,
  query: PropTypes.string,
  inputChange: PropTypes.func,
  clearSearch: PropTypes.func,
  notSearchResult: PropTypes.bool,
  searchResult: PropTypes.array,
  loading: PropTypes.bool,
}

export default SearchDesktop
