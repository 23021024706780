import styled from 'styled-components'
import media from 'styled-media-query'

export const MainHome = styled.main`
  padding-top: ${props => props.theme.headerHeight.desktop};

  ${media.lessThan('medium')`
    padding-top: ${props => props.theme.headerHeight.mobile};
  `}
`
