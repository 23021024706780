import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import MenuItemDesktop from './MenuItemDesktop'
import * as S from './styled'

const MenuItem = ({ menuData }) => {
  const data = useStaticQuery(graphql`
    query {
      menuHighlight: wordpressWpGatsbyPage(slug: { eq: "home" }) {
        acf {
          show_menu_highlight
          menu_highlight {
            target
            title
            url
          }
        }
      }
    }
  `)

  const showHighlight = data.menuHighlight.acf.show_menu_highlight
  const wordpressData = data.menuHighlight.acf.menu_highlight
  const highlight = {
    title: wordpressData.title,
    url: wordpressData.url,
    target: wordpressData.target,
  }

  return (
    <>
      {showHighlight && (
        <S.MenuItem>
          <S.DesktopMenuLinkHighlight href={highlight.url} target={highlight.target}>
            {highlight.title}
          </S.DesktopMenuLinkHighlight>
        </S.MenuItem>
      )}
      {menuData.map((item, index) => (
        <MenuItemDesktop key={index} menuItem={item} />
      ))}
    </>
  )
}

MenuItem.propTypes = {
  menuData: PropTypes.array.isRequired,
}

export default MenuItem
