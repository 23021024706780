import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SubmenuDesktop from '../Submenu'

import * as S from './styled'

const MenuItemDesktop = ({ menuItem: { title, url, wordpress_children: submenu } }) => {
  const [submenuVisible, setSubmenuVisible] = useState(false)
  const hasSubmenu = submenu && submenu.length > 0

  return (
    <S.MenuItem
      onMouseEnter={() => setSubmenuVisible(true)}
      onMouseLeave={() => setSubmenuVisible(false)}
      onClick={() => setSubmenuVisible(isVisible => !isVisible)}
    >
      {hasSubmenu ? (
        <>
          <S.SubmenuTitle visible={submenuVisible}>{title}</S.SubmenuTitle>
          <SubmenuDesktop submenu={submenu} visible={submenuVisible} />
        </>
      ) : (
        <S.MenuLink
          href={url === '' ? '#' : url}
          target={url.match(/^https?:\/\//g) ? '_blank' : ''}
        >
          {title}
        </S.MenuLink>
      )}
    </S.MenuItem>
  )
}

MenuItemDesktop.propTypes = {
  menuItem: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    wordpress_children: PropTypes.array,
  }),
}

export default MenuItemDesktop
