import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'

const Links = ({ footerLinks }) => (
  <>
    {footerLinks.map((links, index) => (
      <S.FooterLinksList key={index}>
        {links.items.map(link => (
          <S.FooterLinksItems key={link.url}>
            {link.url.match(/^https?:\/\//g) ? (
              <S.FooterExternalLink
                key={index}
                target="_blank"
                rel="noopener noreferer"
                href={link.url}
              >
                {link.title}
              </S.FooterExternalLink>
            ) : (
              <S.FooterInternalLink key={index} to={link.url}>
                {link.title}
              </S.FooterInternalLink>
            )}
          </S.FooterLinksItems>
        ))}
      </S.FooterLinksList>
    ))}
  </>
)

Links.propTypes = {
  footerLinks: PropTypes.array.isRequired,
}

export default Links
