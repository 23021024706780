import styled from 'styled-components'
import media from 'styled-media-query'

export const ButtonsContainer = styled.div`
  grid-area: buttons;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 767px) {
    display: none;
  }
`

export const DoctorButton = styled.a`
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${props => props.theme.colors.secondary};
  text-decoration: none;
  opacity: 0.9;
  margin: 0 3.2rem;

  &:hover {
    opacity: 1;
  }
`

export const ExamsResultsButton = styled.a`
  font-size: 1.6rem;
  text-align: center;
  color: ${props => props.theme.colors.white};
  text-decoration: none;
  border-radius: 2px;
  padding: 1.2rem 2.4rem;
  background-color: ${props => props.theme.colors.secondary};
  opacity: 0.9;
  margin-left: 3.2rem;

  &:hover {
    opacity: 1;
  }

  ${media.greaterThan('huge')`
    padding: 1.2rem 3.4rem;
  `}
`
