import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Links from './Links'
import ContactUs from './ContactUs'
import Social from './Social'
import Institutional from './Institutional'
import * as S from './styled'

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
      footerLinks: allWordpressWpApiMenusMenusItems(
        filter: { name: { glob: "*footer*" } }
        limit: 3
        sort: { fields: wordpress_id }
      ) {
        nodes {
          name
          items {
            url
            title
          }
        }
      }

      institutionalLinks: allWordpressWpApiMenusMenusItems(
        filter: { name: { glob: "*institucional*" } }
        limit: 1
      ) {
        nodes {
          name
          items {
            url
            title
          }
        }
      }

      socialsInfo: wordpressAltaThemeSettings {
        footer_alta_logo
        footer_dasa_logo
        technical_manager
        phone_sp
        phone_rj
        phone_df
        instagram_url
        linkedin_url
        youtube_url
        facebook_url
      }
    }
  `)

  const footerLinks = data.footerLinks.nodes

  const institutionalLinks = data.institutionalLinks.nodes
  const { socialsInfo } = data

  return (
    <S.FooterSection>
      <S.FooterContainer>
        <S.FooterGrid>
          <S.ALtaFooterLogo src={socialsInfo.footer_alta_logo} alt="alta-footer-logo" />

          <Links footerLinks={footerLinks} />

          <ContactUs socialsInfo={socialsInfo} />

          <Social socialsInfo={socialsInfo} />

          <S.TechnicalManager>{socialsInfo.technical_manager}</S.TechnicalManager>
        </S.FooterGrid>
      </S.FooterContainer>
      <Institutional institutionalLinks={institutionalLinks} socialsInfo={socialsInfo} />
    </S.FooterSection>
  )
}

export default Footer
