import styled from 'styled-components'
import media from 'styled-media-query'

export const ButtonsContainer = styled.div`
  grid-area: buttons;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: ${props => props.theme.colors.secondary};

  ${media.greaterThan('medium')`
    display: none;
  `}
`

export const DoctorButton = styled.a`
  font-size: 1.6rem;
  line-height: 4.1rem;
  color: ${props => props.theme.colors.white};
  text-decoration: none;
`
