import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const SearchButton = ({ onClick, searchOpen }) => (
  <S.SearchButtonDesktop onClick={onClick} searchOpen={searchOpen} aria-label="Pesquisar">
    {searchOpen ? <S.CloseSearch>Fechar</S.CloseSearch> : <S.SearchIcon size="20" />}
  </S.SearchButtonDesktop>
)

SearchButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  searchOpen: PropTypes.bool,
}

export default SearchButton
