import styled from 'styled-components'
import media from 'styled-media-query'

export const MainSearch = styled.div`
  display: ${props => (props.open ? 'block' : 'none')};
  grid-area: search;
  box-sizing: border-box;
  position: relative;
  z-index: 5;
  align-self: center;
  justify-self: end;

  ${media.greaterThan('medium')`
    width: 90%;
    display: grid;
    grid-template-columns: 11fr 1fr;
    gap: 0;
    grid-template-areas:
      'inputSearch buttonOpenSearh'
      'resultsSeacrh .';

  `}

  @media only screen and (max-width: 767px) {
    justify-content: center;
    align-items: center;
    top: calc(0rem - 4.1rem);
    width: 100%;
    padding: 2rem 2.4rem;
    background-color: ${props => props.theme.colors.primary};
  }
`

export const SeacrhFormResultsContainer = styled.div`
  position: relative;
`

export const Overlay = styled.div`
  display: ${props => (props.showOverlay ? 'block' : 'none')};
  position: absolute;
  left: 0;
  top: 11.6rem;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;

  @media only screen and (max-width: 767px) {
    display: none;
  }
`
