import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { useTranslations } from 'gatsby-plugin-translate'

import * as S from './styled'

const ClearInput = ({ clearSearch }) => {
  const t = useTranslations()
  const translatedClearTolltip = t`Limpar`

  return (
    <>
      <S.CloseIcon onClick={clearSearch} size="20" data-tip={translatedClearTolltip} />
      <ReactTooltip place="right" type="dark" effect="solid" />
    </>
  )
}

ClearInput.propTypes = {
  clearSearch: PropTypes.func.isRequired,
}

export default ClearInput
