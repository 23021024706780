import styled from 'styled-components'
import { Breadcrumb as BreadcrumbPlugin } from 'gatsby-plugin-breadcrumb'
import { Container } from 'components/shared/container'

export const BreadcrumbHolder = styled(Container)`
  padding: 3.2rem 0 0;

  .breadcrumb {
    font-size: 1.4rem;
    display: inline-block;
    color: ${props => props.theme.colors.greyishBrown};
    text-decoration: none solid rgb(125, 125, 125);
    text-transform: uppercase;

    a {
      text-decoration: none;
      color: ${props => props.theme.colors.greyishBrown};

      &:hover {
        color: ${props => props.theme.colors.tertiary};
      }
    }

    .breadcrumb__separator {
      display: inline-block;
      margin: 0 0.5rem;
      color: ${props => props.theme.colors.greyishBrown};
    }

    &:last-of-type > a {
      color: ${props => props.theme.colors.tertiary};
      cursor: default;
    }
  }
`

export const Breadcrumb = styled(BreadcrumbPlugin)``
