import styled from 'styled-components'
import media from 'styled-media-query'
import { SearchOutline } from '@styled-icons/evaicons-outline/SearchOutline'

export const SearchButtonDesktop = styled.button`
  grid-area: buttonOpenSearh;
  justify-self: flex-end;
  align-self: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  margin: 0 0 0 4rem;
  padding: 0;

  @media only screen and (max-width: 767px) {
    display: none;
  }
`

export const SearchIcon = styled(SearchOutline)`
  color: ${props => props.theme.colors.secondary};

  &:hover,
  &:focus {
    transform: ${props => (props.searchButtonCheck ? 'none' : 'scale(1.05)')};
  }
`

export const CloseSearch = styled.p`
  color: ${props => props.theme.colors.secondary};
  font-size: 1.6rem;
  margin: 0;
  cursor: pointer;
  text-decoration: underline;
`
