import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const Institutional = ({ institutionalLinks, socialsInfo: { footer_dasa_logo: dasaLogo } }) => (
  <S.InstitutionalBackground>
    <S.InstitutionalContainerCustom>
      {institutionalLinks.map(links =>
        links.items.map((link, index) =>
          link.url.match(/^https?:\/\//g) ? (
            <S.InstitucionalExternalLinks
              key={index}
              target="_blank"
              rel="noopener noreferer"
              href={link.url}
            >
              {link.title}
            </S.InstitucionalExternalLinks>
          ) : (
            <S.InstitucionalInternalLinks key={index} to={link.url}>
              {link.title}
            </S.InstitucionalInternalLinks>
          )
        )
      )}

      <S.DasaFooterLogo src={dasaLogo} alt="footer-dasa-logo" />
    </S.InstitutionalContainerCustom>
  </S.InstitutionalBackground>
)

Institutional.propTypes = {
  institutionalLinks: PropTypes.array.isRequired,
  socialsInfo: PropTypes.objectOf(
    PropTypes.shape({
      dasaLogo: PropTypes.string,
    }).isRequired
  ).isRequired,
}

export default Institutional
