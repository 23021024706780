import styled from 'styled-components'
import media from 'styled-media-query'
import { SearchOutline } from '@styled-icons/evaicons-outline/SearchOutline'

export const SearchForm = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 0.8rem;
  border-bottom: ${props =>
    props.searchButtonCheck ? `1px solid ${props.theme.colors.secondary};`: `none`};

  @media only screen and (max-width: 767px) {
    border-bottom: 1px solid ${props => props.theme.colors.secondary};
  }
`

export const SearchStaticIcon = styled(SearchOutline)`
  display: ${props => (props.searchButtonCheck ? `block;` : `none`)};
  color: ${props => props.theme.colors.secondary};
  margin: 0 1.6rem;
  width: 3rem;

  @media only screen and (max-width: 767px) {
    display: block;
  }
`

export const SearchInput = styled.input`
  grid-area: inputSearch;
  display: ${props => (props.searchButtonCheck ? `block;` : `none`)};
  width: 100%;
  background-color: transparent;
  border: none;
  color: ${props => props.theme.colors.secondary};
  font-size: 1.6rem;
  outline: none;
  padding: 0;

  @media only screen and (max-width: 767px) {
    display: block;
    font-size: 1.4rem;
  }

  ::-webkit-input-placeholder {
    font-style: italic;
    color: ${props => props.theme.colors.secondary};
  }
  :-moz-placeholder {
    font-style: italic;
    color: ${props => props.theme.colors.secondary};
  }
  ::-moz-placeholder {
    font-style: italic;
    color: ${props => props.theme.colors.secondary};
  }
  :-ms-input-placeholder {
    font-style: italic;
    color: ${props => props.theme.colors.secondary};
  }
`
