import axios from 'axios'
import slug from 'slug'

const mapProducts = product => {
  const path = `${product.categoria.nome}` === 'Vacina' ? '/vacinas/' : '/exames/'
  return {
    nome: product.nome,
    sinonimo: product.sinonimias,
    slug: `${path}${slug(product.nome)}`,
  }
}

const AllProductService = {
  search: async query => {
    try {
      const url = `${process.env.GATSBY_DP_SEARCH_URL}?idMarca=${process.env.GATSBY_BRAND_ID}&q=${query}`
      const result = await axios.get(url)
      return result.status === 204 ? [] : result.data.map(mapProducts)
    } catch (error) {
      return []
    }
  },
}

export default AllProductService
