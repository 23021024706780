import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslations } from 'gatsby-plugin-translate'

import SchedulingButton from '../SchedulingButton'
import * as S from './styled'

const ButtonsDesktop = () => {
  const data = useStaticQuery(graphql`
    query {
      links: wordpressAltaThemeSettings {
        medical_channel_link
        examination_results_link
        scheduling_link_rj
        scheduling_link_sp
        scheduling_link_df
      }
    }
  `)

  const medicalLink = data.links.medical_channel_link
  const examinatioResultslLink = data.links.examination_results_link
  const schedulelLinkSp = data.links.scheduling_link_sp
  const schedulelLinkRj = data.links.scheduling_link_rj
  const schedulelLinkDf = data.links.scheduling_link_df

  const isExternalLink = url => {
    return url.match(/^https?:\/\//g)
  }

  const t = useTranslations()
  const translatedDoctorButton = t`Sou médico`
  const translatedExamResult = t`Resultados de exames`

  return (
    <S.ButtonsContainer>
      <S.DoctorButton href={medicalLink} target={isExternalLink(medicalLink) ? '_blank' : ''}>
        {translatedDoctorButton}
      </S.DoctorButton>
      <SchedulingButton
        schedulelLinkSp={schedulelLinkSp}
        schedulelLinkRj={schedulelLinkRj}
        schedulelLinkDf={schedulelLinkDf}
      />
      <S.ExamsResultsButton
        href={examinatioResultslLink}
        target={isExternalLink(examinatioResultslLink) ? '_blank' : ''}
      >
        {translatedExamResult}
      </S.ExamsResultsButton>
    </S.ButtonsContainer>
  )
}

export default ButtonsDesktop
