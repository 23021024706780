import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { TranslateLink } from 'gatsby-plugin-translate'

import * as S from './styled'

const Logo = ({ onClick }) => {
  const data = useStaticQuery(graphql`
    query {
      wordpressWpLogo {
        id
        url {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      wordpressAltaThemeSettings {
        extra_logo
      }
    }
  `)

  const [scrolled, setScrolled] = useState(false)

  const changeLogo = () => {
    const header = document.querySelector('header')

    if (header) {
      const headerHeight = getComputedStyle(header).height.split('px')[0]
      setScrolled(window.scrollY > headerHeight)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeLogo)

    return () => {
      window.removeEventListener('scroll', null)
    }
  }, [])

  return (
    <S.LogoContainer onClick={onClick}>
      <TranslateLink to="/" aria-label="Ir para Home">
        {scrolled ? (
          <S.LogoExraImg
            src={data.wordpressAltaThemeSettings.extra_logo}
            alt="extra-logo"
            aria-label="Logo"
          />
        ) : (
          <S.LogoImg
            fluid={data.wordpressWpLogo.url.localFile.childImageSharp.fluid}
            loading="eager"
            aria-label="Logo"
          />
        )}
      </TranslateLink>
    </S.LogoContainer>
  )
}

Logo.propTypes = {
  onClick: PropTypes.func,
}

export default Logo
