import styled from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'gatsby'

export const ResultsContainer = styled.div`
  z-index: 1000;
  grid-area: resultsSeacrh;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: ${props => props.theme.colors.white};
  box-sizing: border-box;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
`

export const ResultsLoadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem 2.4rem;
`

export const ResultLabel = styled.p`
  color: ${props => props.theme.colors.secondary};
  font-size: 1.4rem;
  margin: 0;
`

export const LoadingContainer = styled.div`
  width: 4rem;
  ${media.lessThan('medium')`
    display: none;
  `}
`

export const ResultsItem = styled(Link)`
  color: ${props => props.theme.colors.secondary};
  text-decoration: none;
  font-size: 1.4rem;
  padding: 1.5rem 4.4rem;

  &:last-of-type {
    margin-bottom: 2rem;
  }

  /* &:hover {
    background-color: ${props => props.theme.colors.secondary};
  } */
`
