import React from 'react'
import { useHeaderContext } from '../Hooks/headerStates'
import Logo from '../HeaderSharedComponents/Logo'
import Search from '../HeaderSharedComponents/Search'
import Buttons from '../HeaderSharedComponents/Buttons/ButtonsDesktop'
// import LanguageFilter from '../HeaderSharedComponents/LanguageFilter'
import Menu from '../HeaderSharedComponents/Menu/MenuDesktop'

import { useStaticQuery, graphql } from 'gatsby'
import NavLogo from './NavLogo'

import * as S from './styled'

const HeaderDesktop = () => {

  const { nav } = useStaticQuery(
    graphql`
      query {
        nav: wordpressWpGatsbyPage(slug: { eq: "home" }) {
          acf {
            nav_logo
            nav_link
            nav_image {
              localFile {
                url
              }
            }
          }
        }
      }
    `
  )

  const navLogo = nav.acf.nav_logo
  const navLink = nav.acf.nav_link
  const navImage = nav.acf.nav_image.localFile.url

  const searchButtonCheck = useHeaderContext()
  return (
    <>
      <S.Main searchOpen={searchButtonCheck.getSearchOpenState()}>
        <Logo />
        <Search />
        {!searchButtonCheck.getSearchOpenState() && <Buttons />}
        {/* <LanguageFilter /> */}
        {
          navLogo &&
          <S.ContainerNavLogo>
            <NavLogo navLogo={navImage}  navLink={navLink} />
          </S.ContainerNavLogo>
        }
      </S.Main>
      <Menu />
    </>
  )
}
export default HeaderDesktop
