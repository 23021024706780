import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'

const SubmenuDesktop = ({ submenu, visible }) => (
  <>
    <S.SubmenuDesktop visible={visible}>
      {submenu.map((subitem, index) => (
        <S.SubmenuItem key={index}>
          <S.SubmenuLink
            href={subitem.url}
            target={subitem.url.match(/^https?:\/\//g) ? '_blank' : ''}
          >
            {subitem.title}
          </S.SubmenuLink>
        </S.SubmenuItem>
      ))}
    </S.SubmenuDesktop>
    <S.ArrowDownIcon size={20} visible={visible} />
  </>
)

SubmenuDesktop.propTypes = {
  submenu: PropTypes.array,
  visible: PropTypes.bool,
}

export default SubmenuDesktop
