import styled from 'styled-components'
import media from 'styled-media-query'

export const MenuDesktop = styled.ul`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
  padding: 0 15%;
  background-color: ${props => props.theme.colors.secondary};

  ${media.between('medium', 'large')`
    padding: 0 5%;
  `}

  @media only screen and (max-width: 767px) {
    display: none;
  }
`
