import React, { useState, createContext } from 'react'

const HeaderContext = createContext()

export const HeaderProvider = props => {
  const [searchOpen, setSearchOpen] = useState(null)
  const [menuMobileOpen, setMenuMobileOpen] = useState(null)
  const [submenuMobileOpen, setSubmenuMobileOpen] = useState({})

  // search
  const setSearchOpenState = open => {
    setSearchOpen(open)
  }

  const getSearchOpenState = () => {
    return searchOpen
  }

  // menu mobile
  const setMenuMobileOpenState = open => {
    setMenuMobileOpen(open)
  }

  const getMenuMobileOpenState = () => {
    return menuMobileOpen
  }

  // submenu
  const setSubmenuMobileVisibleState = open => {
    setSubmenuMobileOpen(open)
  }

  const getSubmenuMobileVisibleState = () => {
    return submenuMobileOpen
  }

  return (
    <HeaderContext.Provider
      value={{
        setSearchOpenState,
        getSearchOpenState,
        setMenuMobileOpenState,
        getMenuMobileOpenState,
        setSubmenuMobileVisibleState,
        getSubmenuMobileVisibleState,
      }}
      {...props}
    />
  )
}

export const useHeaderContext = () => {
  const context = React.useContext(HeaderContext)
  if (context === undefined) {
    throw new Error('useHeader must be used within a HeaderProvider')
  }
  return context
}
