import styled from 'styled-components'
import media from 'styled-media-query'

export const ContactUsTitle = styled.p`
  grid-column: 2/6;
  font-size: 1.6rem;
  color: ${props => props.theme.colors.greyishBrown};
  font-weight: 600;
  margin: 4rem 0 1.6rem;

  ${media.between('medium', 'huge')`
    font-size: 1.4rem;
    margin: 1.6rem 0;
`}

  ${media.lessThan('medium')`
    grid-column: 1/3;
    grid-row: 4;
    margin: 1.6rem 0;
    color: ${props => props.theme.colors.secondary};
`}
`

export const PhoneWrapper = styled.div`
  &:first-of-type {
    grid-column: 2/3;
  }

  &:nth-of-type(3) {
    margin-top: 2rem;
    grid-row: 4;
    grid-column: 2/3;
  }

  ${media.lessThan('medium')`
    grid-row: 5;
    color: ${props => props.theme.colors.secondary};

    &:nth-of-type(1) {
      grid-column: 1/2;
    }

    &:nth-of-type(2) {
      grid-column: 2/3;
    }

    &:nth-of-type(3) {
      margin-top: 2rem;
      grid-row: 6;
      grid-column: 1/2;
    }
  `}
`

export const PhoneLabel = styled.p`
  font-size: 1.6rem;
  color: ${props => props.theme.colors.greyishBrown};
  margin: 0;
  line-height: 2.4rem;

  ${media.between('medium', 'huge')`
    font-size: 1.4rem;
  `}

  ${media.lessThan('medium')`
    color: ${props => props.theme.colors.secondary};
`}
`

export const PhoneNumber = styled.a`
  font-size: 1.6rem;
  font-family: 'Dasa Sans';
  line-height: 2.4rem;
  color: ${props => props.theme.colors.greyishBrown};
  text-decoration: none;

  ${media.between('medium', 'huge')`
    font-size: 1.4rem;
  `}

  ${media.lessThan('medium')`
    color: ${props => props.theme.colors.secondary};
`}
`
