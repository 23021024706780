import styled from 'styled-components'
import media from 'styled-media-query'
import { KeyboardArrowDown } from '@styled-icons/material-rounded'

export const Label = styled.div`
  position: relative;
  margin: 0;
  cursor: pointer;
  font-size: 1.6rem;
  line-height: 4rem;
  text-decoration: none;
  color: ${props => props.theme.colors.secondary};
  opacity: ${props => (props.visible ? 1 : 0.8)};

  ${media.lessThan('medium')`
    opacity: 1;
    line-height: 4.1rem;
    color: ${props => props.theme.colors.white};
  `}
`

export const ArrowDownIcon = styled(KeyboardArrowDown)`
  color: ${props => props.theme.colors.secondary};
  cursor: pointer;
  ${media.lessThan('medium')`
    color: ${props => props.theme.colors.white};
  `}
`

export const LinksWrapper = styled.ul`
  display: ${props => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  right: -2.2rem;
  width: max-content;
  padding: 0.8rem 0;
  margin: 0;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  z-index: 3;

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    top: -1rem;
    right: 2.2rem;
    border-right: 1rem solid transparent;
    border-left: 1rem solid transparent;
    border-bottom: 1.1rem solid ${props => props.theme.colors.white};
  }
`

export const Link = styled.a`
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${props => props.theme.colors.black};
  text-decoration: none;
  padding: 0.8rem 3.2rem;
`
