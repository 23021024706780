import React from 'react'
import PropTypes from 'prop-types'

import MenuItemMobile from './MenuItemMobile'

const MenuItem = ({ menuData }) => (
  <>
    {menuData.map((item, index) => (
      <MenuItemMobile key={index} menuItem={item} />
    ))}
  </>
)

MenuItem.propTypes = {
  menuData: PropTypes.array.isRequired,
}

export default MenuItem
