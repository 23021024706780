import React from 'react'
import PropTypes from 'prop-types'

import Theme from './Theme'
import GlobalStyle from './GlobalStyle'

import * as S from './styled'
import Header from './Header'
import Breadcrumb from './Breadcrumb'
import Footer from './Footer'

const Layout = ({ children, location, pageContext, hasBreadcrumb = true }) => (
  <Theme>
    <>
      <GlobalStyle />
      <Header />
      <S.MainHome role="main">
        {hasBreadcrumb && <Breadcrumb pageContext={pageContext} location={location} />}
        {children}
      </S.MainHome>
      <Footer />
    </>
  </Theme>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
  hasBreadcrumb: PropTypes.bool,
}

export default Layout
