import styled from 'styled-components'
import media from 'styled-media-query'
import { Instagram, Facebook, Linkedin, Youtube } from '@styled-icons/boxicons-logos'

export const SocialLinksContainer = styled.div`
  display: flex;
  align-items: center;

  ${media.lessThan('medium')`
    margin-bottom: 3.3rem;
    grid-column: 1/3;
    grid-row: 8;
    color: ${props => props.theme.colors.secondary};

  `}
`

export const SocialLinks = styled.a`
  color: ${props => props.theme.colors.greyishBrown};
  margin-right: 3.2rem;
`

// MEDIA ICONS

export const InstagramIcon = styled(Instagram)``

export const FacebookIcon = styled(Facebook)``

export const LinkedinIcon = styled(Linkedin)``

export const YoutubeIcon = styled(Youtube)``
