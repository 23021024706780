import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'

const SubmenuMobile = ({ title, submenu }) => {
  const resetScroll = useRef(null)
  useEffect(() => {
    resetScroll.current.scrollTop = 0
  })

  return (
    <S.SubmenuMobile ref={resetScroll}>
      <S.SubmenuTitleContainer>
        <S.ArrowLeftIcon size={30} visible={false} />
        <S.SubmenuTitle>{title}</S.SubmenuTitle>
      </S.SubmenuTitleContainer>
      {submenu.map((subitem, index) => (
        <S.SubmenuItem key={index}>
          <S.SubmenuLink
            href={subitem.url}
            target={subitem.url.match(/^https?:\/\//g) ? '_blank' : ''}
          >
            {subitem.title}
          </S.SubmenuLink>
        </S.SubmenuItem>
      ))}
    </S.SubmenuMobile>
  )
}

SubmenuMobile.propTypes = {
  title: PropTypes.string,
  submenu: PropTypes.arrayOf(
    PropTypes.shape({
      subitem: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
        subtree: PropTypes.array,
      }),
    })
  ),
}

export default SubmenuMobile
