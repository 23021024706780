import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useHeaderContext } from '../Hooks/headerStates'

import Logo from '../HeaderSharedComponents/Logo'
// import LanguageFilter from '../HeaderSharedComponents/LanguageFilter'
import Buttons from '../HeaderSharedComponents/Buttons/ButtonsMobile'
import Search from '../HeaderSharedComponents/Search'
import Menu from '../HeaderSharedComponents/Menu/MenuMobile'

import * as S from './styled'

const HeaderMobile = () => {
  const data = useStaticQuery(graphql`
    query {
      linkHighlight: wordpressWpGatsbyPage(slug: { eq: "home" }) {
        acf {
          show_menu_highlight
          menu_highlight {
            target
            title
            url
          }
        }
      }
    }
  `)

  const showHighlight = data.linkHighlight.acf.show_menu_highlight
  const wordpressData = data.linkHighlight.acf.menu_highlight
  const linkHighlight = {
    title: wordpressData.title,
    url: wordpressData.url,
    target: wordpressData.target,
  }

  const menuMobileCheck = useHeaderContext()
  const menuOpenState = menuMobileCheck.getMenuMobileOpenState()

  const scrollToTopMenu = () => {
    const menu = document.getElementById('menuMobile')
    const isFirefox = typeof InstallTrigger !== 'undefined'
    menu.scrollTo({ top: 0, left: 0, behavior: isFirefox ? 'auto' : 'smooth' })
  }

  const openMenuMobile = () => {
    menuMobileCheck.setMenuMobileOpenState(isOpen => !isOpen)
    menuMobileCheck.setSubmenuMobileVisibleState(false)
    scrollToTopMenu()
  }

  useEffect(() => {
    const altaBody = document.getElementsByTagName('BODY')[0]
    if (menuOpenState) {
      altaBody.classList.add('block-scroll')
    } else {
      altaBody.classList.remove('block-scroll')
    }
  }, [menuOpenState])

  return (
    <S.Container>
      <S.Main className={menuMobileCheck.getMenuMobileOpenState()}>
        <Logo onClick={() => menuMobileCheck.setMenuMobileOpenState(false)} />
        {/* <LanguageFilter /> */}
        {showHighlight && (
          <S.HeaderHighlightLink href={linkHighlight.url} target={linkHighlight.target}>
            {linkHighlight.title}
          </S.HeaderHighlightLink>
        )}
        <S.MenuButton onClick={openMenuMobile} aria-label="Menu">
          {menuOpenState ? <S.MenuCloseIcon size="30" /> : <S.MenuIcon size="30" />}
        </S.MenuButton>
      </S.Main>
      <Buttons />
      <Search />
      <Menu />
    </S.Container>
  )
}

export default HeaderMobile
