import baseTheme from './baseTheme'

const blueLightBlue = {
  blue: '#3BD1D6',
  mediumBlue: '#3BD1D6',
  lightBlue: '#3BD1D6',
}

const altaTheme = {
  colors: {
    primary: '#3BD1D6',
    secondary: '#000E40',
    tertiary: '#B55635',
    white: '#FFFFFF',
    black: '#000E40',
    warmGray: '#888686',
    brownishGrey: '#000E40',
    pinkishGreyTwo: '#d2d1d1',
    greyishBrown: '#000E40',
    extraLightGray: '#F2F2F2',
    darkBrown: '#241305',
    mediumTan: '#B55635',
    lighterGray: '#777474',
    lightGray: '#E9E9E9',
    mediumGray: '#d8d8d8',
    darkGray: '#4A4A4A',
    darkerGray: '#353434',
    beigeGray: '#000E40',
    dirtWhite: '#fafaf9',
    notChecked: '#01373F',
    danger: '#B20221',
    alta: '#3BD1D6'
  },
  container: {
    desktop: '9.6rem',
    mobile: '2.4rem',
  },
  headerHeight: {
    desktop: '11.6rem',
    mobile: '10.7rem',
  },
}

export default ({ ...baseTheme, ...altaTheme })
