import React from 'react'
import PropTypes from 'prop-types'
import { useTranslations } from 'gatsby-plugin-translate'
import ClearInput from './ClearInput'
import * as S from './styled'

const SearchForm = ({ handleSubmit, searchOpen, query, inputChange, clearSearch }) => {
  const t = useTranslations()
  const translatedSearchExamsOrVaccinesMessage = t`Buscar exames ou vacinas`

  return (
    <S.SearchForm id="searchForm" onSubmit={handleSubmit} searchButtonCheck={searchOpen}>
      <S.SearchStaticIcon id="search-icon" size="20" searchButtonCheck={searchOpen} />
      <S.SearchInput
        id="search-query"
        autoComplete="off"
        type="text"
        placeholder={translatedSearchExamsOrVaccinesMessage}
        value={query}
        onChange={inputChange}
        searchButtonCheck={searchOpen}
      />
      {query !== '' && <ClearInput size="30" id="search-clear" clearSearch={clearSearch} />}
    </S.SearchForm>
  )
}

SearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  searchOpen: PropTypes.bool,
  query: PropTypes.string.isRequired,
  inputChange: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
}

export default SearchForm
